import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SelectOption } from '../../interfaces/select-option.interface';
import { ChipListComponent } from '../chip-list/chip-list.component';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'app-multi-select-dropdown-chips',
  standalone: true,
  imports: [DropdownComponent, ChipListComponent],
  templateUrl: './multi-select-dropdown-chips.component.html'
})
export class MultiSelectDropdownChipsComponent implements OnInit {

  @Input() placeholder: string;
  @Input() label: string;
  @Input() options: SelectOption[];
  @Input() selectedOptionIds: Array<number | string>;
  @Input() enableUpdateAll: boolean;
  @Input() returnFull: boolean;
  selectedOptions: SelectOption[];
  @Output() onChangeSelected: EventEmitter<number[] | string[] | SelectOption[]>; 

  constructor() {
    this.options = [];
    this.selectedOptions = [];
    this.onChangeSelected = new EventEmitter<number[] | string[]>();
  }

  ngOnInit(): void {
    this.setSelectedOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
			if (changes.hasOwnProperty(propName)) {
        if (propName === "options" || propName === "selectedOptionIds") {
          this.setSelectedOptions();
				}
			}
		}
  }

  private setSelectedOptions(): void {
    if (this.selectedOptionIds && this.options) {
      this.selectedOptions = this.options
        .filter((e) => { return this.selectedOptionIds.includes(e.value); })
        .map((e) => { return { value: e.value, text: e.text } });
    }
    
    this.selectedOptions.forEach((c) => {
      this.changeOptionStatus(true, c.value);
    });
  }

  addOption(optionId: number | string): void {
    if (optionId && !this.isSelected('value', optionId)) {
      const newOption: SelectOption = this.options.find((c) => { return c.value === optionId });
      this.changeOptionStatus(true, optionId);
      this.selectedOptions.push({ value: optionId, text: newOption.text });
      this.emitSelectedOptions();
    }
  }

  removeOption(optionId: number | string): void {
    this.changeOptionStatus(false, optionId);
    this.selectedOptions = this.selectedOptions?.filter((e) => { return e.value !== optionId });
    this.emitSelectedOptions();
  }

  addAllOptions(): void {
    this.changeOptionStatus(true);
    this.selectedOptions = [...this.options];
  }

  removeAllOptions(): void {
    this.changeOptionStatus(false);
    this.selectedOptions = [];
  }

  private isSelected(property: string, optionId: number | string): boolean {
    return this.selectedOptions.some((c) => { return this.selectedOptions[property] === optionId });
  }

  private changeOptionStatus(hidden: boolean, optionId?: number | string): void {
    if (!optionId) {
      this.options.forEach((c) => { c.hidden = hidden; });
    } else {
      const option: SelectOption = this.options?.find((o) => { return o.value === optionId });
      if (option) {
        option.hidden = hidden;
      }
    }
  }

  private emitSelectedOptions(): void {
    if (this.returnFull) {
      this.onChangeSelected.emit(this.selectedOptions);
    } else {
      this.onChangeSelected.emit(
        this.selectedOptions.map((e) => {
          return e.value
        })
      );
    }
  }

}
