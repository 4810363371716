<div class="flex flex-col justify-center items-start mb-4">
  <app-dropdown [placeholder]="placeholder"
                [label]="label"
                [fullWidth]="true"
                [options]="options"
                [search]="true"
                [cleanOnSelect]="true"
                class="block w-full flex-grow"
                (onchange)="addOption($event)" />

  @if (enableUpdateAll) {
    <div class="flex items-center mt-2">
      <span class="block mr-4"><a (click)="addAllOptions()">+ Add All</a></span>
      <span><a (click)="removeAllOptions()">- Remove All</a></span>
    </div>
  }
  
  @if (selectedOptions?.length) {
    <div class="my-2">
      <app-chip-list [items]="selectedOptions" (remove)="removeOption($event)" />
    </div>
  }
</div>