import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOption } from 'src/app/shared/interfaces/select-option.interface';
;

@Component({
  selector: 'app-chip-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent {
  @Input() items: Array<SelectOption>;
  @Output() remove: EventEmitter<number>;

  constructor() {
    this.items = [];
    this.remove = new EventEmitter<number>();
  }
  
  removeItem(value: number): void {
    this.remove.emit(value);
  }
}