<div class="flex flex-wrap">
  @for (item of items; track $index) {
    <div class="flex items-center mr-1 mb-1 rounded-3xl chip">
      <span class="text-sm text-black">{{ item.text }}</span>
      <button
        (click)="removeItem(item.value)"
        type="button"
        class="ml-2.5 text-black text-sm"
        title="Remove">
        X
      </button>
    </div>
  }
</div>